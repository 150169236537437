@import 'factor-lib/scss/base/layouts';
@import 'factor-lib/scss/base/borders';
@import 'factor-lib/scss/base/background';
@import 'factor-lib/scss/base/typography';

.seller-summary {

    @extend .p-background;

    padding: $space-6;
    border-radius: $border-radius;
    //background: $white-4;

    table {
        border-collapse: separate;
        border-spacing: $space-4;
        table-layout: initial;
        width: 100%;
    }

    th {
        // Reset default value
        font-weight: inherit;
    }

    td {
        font-weight: 700; // $weight-bold;
    }

    .iban {
        // Allow iban to wrap, to avoid overflow
        overflow-wrap: break-word;
    }
}
