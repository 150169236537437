@import 'factor-lib/scss/base/typography';

// For buyer, default is 16px (only used in 2 or 3 places for now)
$body-font-size: $size-62;

@import "bulma/sass/helpers/typography.sass";

// J'ai recupéré ce qu'il y avait avant. A factoriser ?
$box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.1), 0 0px 0 1px rgba(0, 0, 0, 0.02);

@import "bulma/sass/elements/box.sass";
@import "bulma/sass/elements/tag.sass";
@import "bulma/sass/elements/container.sass";

@import "bulma/sass/grid/columns.sass";

@import 'factor-lib/index.scss';