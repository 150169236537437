// TODO : remove
@import 'bulma/sass/utilities/mixins';
@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/base/layouts';
@import 'factor-lib/scss/times';
@import 'factor-lib/scss/base/typography';

.p-cs-title {
    $size-default: 1em; // TODO
}

// Why is this needed ?
.amount-line {
    // Align the label and amount on the baseline
    align-items: baseline;
}

.coverage {
    border-radius: 4px; // TODO : reuse ours
    position: relative;

    overflow: hidden;
}
