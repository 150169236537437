@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/components/form';

@import "bulma/sass/utilities/mixins";

// Pk ne pas utiliser p-horizontal-divider ?

hr {
    background: $border-default-color;
}

.is-fixed-mobile {
    // TODO : clean this
    @include until($desktop) {
        // Remove bottoms border-radiuses
        border-radius: 0;
        box-shadow: $black; // $drop-shadow-huge-around;

        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
    }
}

.nothing-text {
    color: $green;
    font-size: xx-large;
}
